<template>
  <div v-loading.fullscreen.lock="fullscreenLoading" class="statement-details">
    <div class="statement-details--title">
      <h3>供应商对账单</h3>
      <div class="title-action">
        <el-upload
          ref="uploadFile"
          style="display:inline-block;"
          action=""
          accept=".xlsx, .xls"
          :http-request="uploadDateFile"
          :limit="1"
          :file-list="fileList"
          :auto-upload="true"
          :on-change="fileDateChange"
          :show-file-list="false"
        >
          <el-button v-if="isAction" type="text" class="mr-4" :loading="importLoading">对账导入</el-button>
        </el-upload>
        <el-button type="text" :loading="vendorBillExportLoading" @click="handleVendorBillExport">导出</el-button>
      </div>
    </div>
    <vxe-table
      ref="statementTable"
      max-height="500px"
      border
      :data="vendorBillTableData"
      align="center"
      :cell-style="cellStyle"
      show-footer-overflow
      show-footer
      :footer-method="footerMethod"
    >
      <vxe-table-column type="seq" width="60" title="序号" />
      <vxe-table-column field="estimationPayableCode" title="暂估单号" min-width="200" />
      <vxe-table-column field="purchaseOrderCode" title="采购单号" min-width="200" />
      <vxe-table-column field="collectCode" title="收货单号" min-width="200" />
      <vxe-table-column field="collectTime" title="收货时间" min-width="200" />
      <vxe-table-column field="warehouseName" title="收货仓" min-width="100" />
      <vxe-table-column field="vendorName" title="供应商名称" min-width="100" />
      <vxe-table-column field="deliveryTime" title="送货时间" min-width="200" />
      <vxe-table-column field="currency" title="币种" min-width="80" />
      <vxe-table-column field="style" title="Style" min-width="140" />
      <vxe-table-column field="taxRatio" title="税率%" min-width="80" />
      <vxe-table-column field="collectPairs" title="实收数量" min-width="100" />
      <vxe-table-column field="reconcilePairs" title="对账数量" min-width="100" />
      <vxe-table-column field="reconcileAmount" title="对账金额合计" width="120" />
      <vxe-table-column field="remark" title="操作" min-width="100">
        <template #default="{ row: { purchaseOrderCode, obdcBySkuVOList, uniqueId }  }">
          <el-button type="text" @click="handleDetailView(purchaseOrderCode, obdcBySkuVOList, uniqueId)">
            查看详情
          </el-button>
        </template>
      </vxe-table-column>
    </vxe-table>
    <div class="return-difference-bill">
      <div class="return-difference-bill--title">
        <h4>退货及差异对账单</h4>
        <el-button type="text" class="ml-5" @click="handleReturnBillExport">导出</el-button>
      </div>
      <vxe-table
        ref="xTable"
        max-height="500px"
        :data="returnDifferenceBillTableData"
        align="center"
        show-footer-overflow
        show-footer
        :footer-method="footerMethod"
      >
        <vxe-table-column type="seq" width="60" title="序号" />
        <vxe-table-column field="estimationPayableCode" title="暂估应付单" />
        <vxe-table-column field="purchaseOrderCode" title="采购单号" />
        <vxe-table-column field="cargoOwnerName" title="采购主体" />
        <vxe-table-column field="vendorName" title="供应商" />
        <vxe-table-column field="paymentTypeI18" title="付款类型" />
        <vxe-table-column field="totalPrice" title="单据金额" />
      </vxe-table>
      <el-button v-if="isAction" :loading="submitLoading" class="footer-button" type="primary" @click="handleSubmit">
        确认提交</el-button>
    </div>

    <el-dialog
      :title="selectPurchaseOrderCode"
      :visible.sync="detailsVisible"
      :close-on-click-modal="false"
      width="90vw"
      center
    >
      <vxe-table
        ref="detailsTable"
        max-height="500px"
        :data="detailsTableData"
        :footer-method="footerMethod"
        :edit-config="isAction ? {trigger: 'click', mode: 'cell', showStatus: true} : {}"
        :edit-rules="isAction ? validRules : {}"
        highlight-hover-row
        align="center"
        :cell-style="cellDetailStyle"
        keep-source
        show-footer-overflow
        show-footer
      >
        <vxe-table-column type="seq" width="60" title="序号" />
        <vxe-table-column field="purchaseOrderCode" title="采购单号" min-width="200" />
        <vxe-table-column field="sku" title="SKU" min-width="260" />
        <vxe-table-column field="taxRatio" title="税率%" min-width="80" />
        <vxe-table-column field="specifications" title="规格型号" min-width="200">
          <template #default="{ row : { specifications, style, color, size} }">
            {{ specifications || `${style}/${color}/${size}` }}
          </template>
        </vxe-table-column>
        <vxe-table-column field="fnsku" title="FNSKU" min-width="160" />
        <vxe-table-column field="upc" title="UPC" min-width="160" />
        <vxe-table-column field="unitPrice" title="单价" min-width="80" />
        <vxe-table-column field="changeAfterHaveTaxPrice" title="调整后单价" min-width="100" />
        <vxe-table-column field="collectPairs" title="数量" min-width="100" />
        <vxe-table-column
          field="reconcilePairs"
          title="对账数量"
          min-width="140px"
          :min="0"
          :edit-render="isAction ? {} : { enabled: false }"
        >
          <template #edit="{ row }">
            <vxe-input
              v-model="row.reconcilePairs"
              type="integer"
              align="center"
              @blur="calculationReconcileAmount(row)"
            />
          </template>
        </vxe-table-column>
        <vxe-table-column field="amount" title="金额合计" min-width="100" />
        <vxe-table-column field="reconcileAmount" title="对账金额合计" min-width="200" align="center" />
      </vxe-table>
      <div class="form-item">
        <label for="season">差异原因</label>
        <el-input
          v-model="season"
          name="season"
          type="textarea"
          :rows="2"
          placeholder="当存在差异时，必须填写差异原因才能提交"
          :maxlength="100"
          :show-word-limit="false"
          :autosize="{ minRows: 2, maxRows: 4 }"
        />
      </div>
      <div v-if="isDifferentialRejection" class="form-item">
        <label for="mark">备注</label>
        <div class="mark-content">
          <div v-for="item in reasonList" :key="item.id" class="mark-item">
            <p class="ma-0">{{ item.reasonText }}</p>
            <span>{{ item.createTime }}</span>
          </div>
        </div>
      </div>
      <span slot="footer">
        <el-button @click="detailsVisible = false">关闭</el-button>
        <el-button v-if="isAction" type="primary" @click="handleSaveDetails">保存</el-button>
      </span>
    </el-dialog>

    <el-dialog title="导入详情" :visible.sync="importVisible" width="90vw" center>
      <vxe-table ref="xTable" max-height="500px" :data="importTableData" align="center">
        <vxe-table-column type="seq" width="60" title="序号" />
        <vxe-table-column field="errorMsg" title="错误信息" min-width="200" />
        <vxe-table-column field="purchaseOrderCode" title="采购订单" min-width="200" />
        <vxe-table-column field="collectCode" title="收货单号" min-width="200" />
        <vxe-table-column field="collectTime" title="收货时间" min-width="200" />
        <vxe-table-column field="collectWarehouse" title="收货仓" min-width="100" />
        <vxe-table-column field="deliveryTime" title="送货时间" min-width="200" />
        <vxe-table-column field="sku" title="SKU" min-width="260" />
        <vxe-table-column field="upc" title="UPC" min-width="100" />
        <vxe-table-column field="fnsku" title="FNSKU" min-width="100" />
        <vxe-table-column field="specifications" title="规格型号" min-width="200" />
        <vxe-table-column field="taxRatio" title="税率%" min-width="80" />
        <vxe-table-column field="changeAfterHaveTaxPrice" title="调整后单价" min-width="100" />
        <vxe-table-column field="reconcilePairs" title="对账数量" min-width="100" />
        <vxe-table-column field="reconcileAmount" title="对账金额合计" min-width="120" />
      </vxe-table>
      <div class="form-item">
        <label for="season">差异原因</label>
        <el-input
          v-model="season"
          name="season"
          type="textarea"
          :rows="2"
          placeholder="当存在差异时，必须填写差异原因才能提交"
          :maxlength="100"
          :show-word-limit="false"
          :autosize="{ minRows: 2, maxRows: 4 }"
        />
      </div>
      <span slot="footer">
        <el-button @click="importVisible = false">取消</el-button>
        <el-button type="primary" :loading="importSubmitLoading" @click="handleImportSubmit">提交</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { isJson } from '@/utils'
import XEUtils from 'xe-utils'
import NP from 'number-precision'
import {
  getReturnDifferenceBillData,
  vendorReconcileSubmit,
  vendorBillExport,
  vendorReconcileImportView,
  vendorBillByStyleByVendorId,
  vendorReconcileSubmitTip
} from '@/api/vendorPurchase-api.js'
import { cloneDeep, uniqueId, isEqual } from 'lodash'
import dayjs from 'dayjs'
export default {
  data() {
    return {
      season: '',
      selectPurchaseOrderCode: '',
      selectId: null,
      importLoading: false,
      importSubmitLoading: false,
      vendorBillExportLoading: false,
      fullscreenLoading: false,
      detailsVisible: false,
      importVisible: false,
      file: '',
      fileList: [],
      vendorBillTableData: [],
      returnDifferenceBillTableData: [],
      freezeVendorBillTableData: [],
      importTableData: [],
      detailsTableData: [],
      submitLoading: false,
      validRules: {
        reconcilePairs: [{ required: true, message: '对账数量必须填写' }]
      }
    }
  },
  computed: {
    isAction() {
      return +this.$route.query.reconcileStatus !== 4
    },
    isDifferentialRejection() {
      return +this.$route.query.reconcileStatus === 5
    },
    isIframe() {
      return window.location.href.includes('iframe')
    },
    routeQueryForm() {
      return isJson(this.$route.query.form) ? JSON.parse(this.$route.query.form) : {}
    },
    outBillDetailAndReturnDiffParams() {
      const { id } = this.$route.query
      if (this.isIframe) {
        return { id, params: {}}
      } else {
        return { id, params: this.routeQueryForm }
      }
    },
    vendorReconcileSubmitParams() {
      return this.vendorBillTableData.reduce((acc, cur) => {
        acc.push(...cur.obdcBySkuVOList)
        return acc
      }, [])
    },
    reasonList() {
      return this.vendorBillTableData.find(item => item.uniqueId === this.selectId)?.reasonReconcilList || []
    }
  },
  mounted() {
    this._billDetail()
  },
  methods: {
    async _billDetail() {
      try {
        this.fullscreenLoading = true
        const { id, params } = this.outBillDetailAndReturnDiffParams
        const [vendorBill, returnDifferenceBill] = await Promise.all([
          vendorBillByStyleByVendorId(id, params),
          getReturnDifferenceBillData(id, params)
        ])
        const { datas: vendorBillTableData } = vendorBill
        const { datas: returnDifferenceBillTableData } = returnDifferenceBill
        this.vendorBillTableData = vendorBillTableData.map(item => ({ ...item, uniqueId: uniqueId('vendorBillId_') }))
        this.returnDifferenceBillTableData = returnDifferenceBillTableData
        // 冻结初始数据
        this.freezeVendorBillTableData = Object.freeze(cloneDeep(this.vendorBillTableData))
        // console.log(this.freezeVendorBillTableData)
      } finally {
        this.fullscreenLoading = false
      }
    },
    async uploadDateFile() {
      try {
        this.importLoading = true
        const form = new FormData()
        form.append('file', this.file)
        const { datas } = await vendorReconcileImportView(form)
        this.importTableData = datas
        this.season = ''
        this.importVisible = true
      } finally {
        this.file = ''
        this.fileList = []
        this.importLoading = false
        this.$refs.uploadFile.clearFiles()
      }
    },
    fileDateChange(file, fileList) {
      this.fileList = fileList
      this.file = file.raw
    },
    async handleVendorBillExport() {
      try {
        this.vendorBillExportLoading = true
        const { datas } = await vendorBillExport(this.$route.query.id, this.routeQueryForm)
        window.open(datas, '_blank')
      } finally {
        this.vendorBillExportLoading = false
      }
    },
    handleReturnBillExport() {
      import('@/vendor/Export2Excel').then(excel => {
        const header = ['序号', '暂估单号', '采购单号', '采购主体', '供应商', '付款类型', '单据金额']
        const filterVal = ['no', 'estimationPayableCode', 'purchaseOrderCode', 'cargoOwnerName', 'vendorName', 'paymentTypeI18', 'totalPrice']
        const data = this.formatJson(filterVal, this.returnDifferenceBillTableData)
        excel.export_json_to_excel({
          header,
          data,
          filename: `An List ${dayjs(new Date()).format('YYYY/MM/DD')}`
        })
      })
    },
    handleSubmit() {
      try {
        this.submitLoading = true
        if (this.vendorReconcileSubmitParams.length) {
          return new Promise(async(resolve) => {
            const { datas } = await vendorReconcileSubmitTip(this.vendorReconcileSubmitParams)
            datas && this.$confirm(datas, '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消'
            }).then(async() => {
              await vendorReconcileSubmit(this.vendorReconcileSubmitParams)
              this.$message.success('提交成功')
              !this.isIframe && this.$router.go(-1)
            })
            !datas && resolve()
          }).then(() => {
            return new Promise(async(resolve) => {
              await vendorReconcileSubmit(this.vendorReconcileSubmitParams)
              this.$message.success('提交成功')
              !this.isIframe && this.$router.go(-1)
              resolve()
            })
          })
        } else {
          this.$message.warning('暂无可提交的数据')
        }
      } finally {
        this.submitLoading = false
      }
    },
    async handleImportSubmit() {
      this.importSubmitLoading = true
      await new Promise(resolve => setTimeout(resolve, 500))
      console.time('计算')
      if (this.importTableData.some(item => item.errorMsg)) {
        this.$message.warning('存在错误信息，请核对后操作')
        return
      }
      // 版本二
      for (let i = 0; i < this.freezeVendorBillTableData.length; i++) {
        const freezeVendorBillItem = this.freezeVendorBillTableData[i]
        // sameItem 导入详情里过滤后的单个详情数组
        const sameItem = (this.importTableData.filter(item => item.purchaseOrderCode === freezeVendorBillItem.purchaseOrderCode && item.collectCode === freezeVendorBillItem.collectCode) || [])
          .map(item => ({ sku: item.sku, changeAfterHaveTaxPrice: item.changeAfterHaveTaxPrice, reconcilePairs: item.reconcilePairs }))
          .sort()
        if (sameItem.length === 0) continue
        const freezeVendorBillDetailsItem = (freezeVendorBillItem.obdcBySkuVOList || [])
          .map(item => ({ sku: item.sku, changeAfterHaveTaxPrice: item.changeAfterHaveTaxPrice, reconcilePairs: item.reconcilePairs }))
          .sort()

        if (!isEqual(sameItem, freezeVendorBillDetailsItem) && this.season?.trim() === '') {
          this.$message.warning('当存在差异时，必须填写差异原因才能提交')
          this.importSubmitLoading = false
          return
        }
      }
      // 整合所有明细，与导入明细一致则替换
      const allDetailsList = this.vendorBillTableData.reduce((acc, cur) => {
        acc.push(...cur.obdcBySkuVOList)
        return acc
      }, [])
        .map(item => {
          // 保留原数据的单价和数量,暂估单号
          const { unitPrice, collectPairs, estimationPayableCode } = item
          const sameItem = this.importTableData.find(importItem =>
            item.sku === importItem.sku &&
            item.collectCode === importItem.collectCode && item.purchaseOrderCode === importItem.purchaseOrderCode) || {}
          return { ...item, ...sameItem, unitPrice, collectPairs, estimationPayableCode }
        })
      // 替换导入明细，计算明细
      this.vendorBillTableData = this.vendorBillTableData.map(item => {
        const obdcBySkuVOList = allDetailsList.filter(allDetailsItem => allDetailsItem.purchaseOrderCode === item.purchaseOrderCode && allDetailsItem.collectCode === item.collectCode)
        const { reconcileAmount, reconcilePairs } = obdcBySkuVOList.reduce((acc, cur) => {
          const { reconcileAmount, reconcilePairs } = cur
          acc.reconcileAmount = NP.round(NP.plus(acc.reconcileAmount, reconcileAmount), 2)
          acc.reconcilePairs = NP.plus(acc.reconcilePairs, reconcilePairs)
          return acc
        }, { reconcileAmount: 0, reconcilePairs: 0 })
        if (this.season) {
          obdcBySkuVOList.forEach(item => { item.reasonText = this.season })
        }
        return Object.assign(item, {
          obdcBySkuVOList,
          reconcileAmount,
          reconcilePairs,
          changeAfterHaveTaxPrice: NP.round(NP.divide(reconcileAmount, reconcilePairs), 2)
        })
      })
      console.timeEnd('计算')
      this.importVisible = false
      this.importSubmitLoading = false
    },
    async handleSaveDetails() {
      if (!await this.detailsFullValid()) return
      const isDiff = this.detailsTableData.find(item => {
        const { unitPrice, collectPairs, changeAfterHaveTaxPrice, reconcilePairs } = item
        return +unitPrice !== +changeAfterHaveTaxPrice || +collectPairs !== +reconcilePairs
      })
      if (isDiff && this.season?.trim() === '') {
        this.$message.warning('当存在差异时，必须填写差异原因才能提交')
        return
      }
      const selectItem = this.vendorBillTableData.find((item) => item.uniqueId === this.selectId)
      const { reconcileAmount, reconcilePairs } = this.detailsTableData.reduce((acc, cur) => {
        const { reconcileAmount, reconcilePairs } = cur
        acc.reconcileAmount = NP.round(NP.plus(acc.reconcileAmount, reconcileAmount), 2)
        acc.reconcilePairs = NP.plus(acc.reconcilePairs, reconcilePairs)
        return acc
      }, { reconcileAmount: 0, reconcilePairs: 0 })
      if (this.season) {
        this.detailsTableData.forEach(item => { item.reasonText = this.season })
      }
      Object.assign(selectItem, {
        reconcileAmount,
        reconcilePairs,
        changeAfterHaveTaxPrice: NP.round(NP.divide(reconcileAmount, reconcilePairs), 2),
        obdcBySkuVOList: this.detailsTableData
      })
      this.$nextTick(() => {
        this.$refs.statementTable.updateData()
        this.detailsVisible = false
      })
    },
    handleDetailView(purchaseOrderCode, skuList, uniqueId) {
      this.selectId = uniqueId
      this.selectPurchaseOrderCode = purchaseOrderCode
      this.detailsTableData = cloneDeep(skuList)
      this.season = this.detailsTableData[0]?.reasonText || ''
      this.detailsVisible = true
    },
    calculationReconcileAmount(row) {
      row.reconcileAmount = NP.times(row.reconcilePairs, row.changeAfterHaveTaxPrice)
    },
    async detailsFullValid() {
      const errMap = await this.$refs.detailsTable.fullValidate().catch(errMap => errMap)
      if (errMap) {
        const msgList = Object.values(errMap).reduce((acc, cur) => {
          cur.forEach(params => {
            const { rowIndex, column, rules } = params
            rules.forEach(rule => {
              acc.push(`第 ${rowIndex + 1} 行 ${column.title} 校验错误：${rule.message}`)
            })
          })
          return acc
        }, [])
        this.$message({
          dangerouslyUseHTMLString: true,
          message: msgList.join('<br/>'),
          type: 'warning'
        })
        return false
      }
      return true
    },
    footerMethod({ columns, data }) {
      return [
        columns.map((column, columnIndex) => {
          if (columnIndex === 0) {
            return '合计'
          }
          if (['collectPairs', 'reconcilePairs', 'reconcileAmount', 'totalPrice', 'amount'].includes(column.property)) {
            return XEUtils.sum(data, column.property)
          }
          return '-'
        })
      ]
    },
    cellDetailStyle({ columnIndex, row }) {
      if (columnIndex === 8) {
        if (row['changeAfterHaveTaxPrice'] !== row['unitPrice']) {
          return {
            background: '#EEEE00'
          }
        }
      }
    },
    cellStyle({ row, column, rowIndex, columnIndex }) {
      const diffKey = (list) => {
        return list.map(({ purchaseOrderCode, sku, reconcilePairs, collectPairs }) => ({ purchaseOrderCode, sku, reconcilePairs: +reconcilePairs, collectPairs: +collectPairs })).sort()
      }

      if (columnIndex === 12) {
        const diffItem = diffKey(row.obdcBySkuVOList)
        if (row['reconcilePairs'] !== row['collectPairs'] || diffItem.find(item => NP.minus(item.collectPairs, item.reconcilePairs) !== 0)) {
          return {
            background: '#EEEE00'
          }
        }
      }
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map((v, i) => filterVal.map(j => {
        if (j === 'no') {
          return i + 1
        } else {
          return v[j]
        }
      }))
    }
  }
}
</script>

<style lang="scss" scoped>
.statement-details .statement-details--title {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-bottom: 20px;
  .title-action {
    position: absolute;
    left: calc(50% + 8em);
  }
  h3 {
    margin: 0;
  }
}
.return-difference-bill .return-difference-bill--title {
  display: flex;
  margin-top: 40px;
}
.footer-button {
  display: block;
  margin: 20px auto;
}
.form-item {
  display: flex;
  margin-top: 15px;
  label {
    text-align: right;
    width: 6em;
    padding-right: 1em;
  }
  .mark-content {
    width: 100%;
  }
  .mark-item {
    display: flex;
    padding: 5px;
    margin-bottom: 10px;
    background: #f4f7fa;
    justify-content: space-between;
    width: 100%;
  }
}
</style>
